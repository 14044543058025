<template>

  <n-grid x-gap="12" y-gap="30" :cols="3" v-if="accountinfo_global.username">
    <n-grid-item :offset="1">


      <n-space justify="space-between">
     <n-image
    width="100"
    src="https://assets.dtc.beer/assets/DTC_LOGO_light.png"
  />
     <h1>Attribution requester v0.1 </h1></n-space>


    </n-grid-item>
    <n-grid-item :offset="1">  
        <n-text type="info"  v-if="!success.length">Hello {{ accountinfo_global.name }}, Please fill the form in order to calculate a new Attribution : {{ posts.campaign }}</n-text>
    </n-grid-item>
    <n-grid-item :offset="0" span="3  "> 
        <form  @submit="checkForm" method="post">

            <n-space vertical :size="12">
              <n-alert  v-if="success.length"  title="Success Text" type="success">
                Thank you for the form submission ! You will receive an email notification once the request has been handled, this usually takes from 5 to 30 minutes.
              </n-alert>
              <n-alert  v-if="errors.length"  title="Error Text" type="error"> Please check if the information in the form is correct</n-alert>
            </n-space>
            <n-p>
              You have selected {{ checkedRowKeys.length }} row(s).
            </n-p>

            <n-space vertical   v-if="!success.length">
                <n-data-table
                  ref="table"
                  :columns="columns"
                  :data="campaign_categories_options"
                  :max-height="800"
                  :min-height="500"
                  :scroll-x="1800"
                  :row-key="row => row.Category + '#|#' + row.Campaign+ '#|#' + row.Scope"
                  :pagination="pagination"
                  @update:checked-row-keys="handleCheck"
                />
              <n-space justify="start"><n-text type="warning" >Choose your date range : </n-text></n-space>
              <n-date-picker v-model:value="range" type="daterange" clearable />
              <n-input v-model:value="accountinfo_global.username" :default-value="accountinfo_global.name" type="text" />
              <n-button type="primary" attr-type="submit" size="large">Submit your request</n-button>
            </n-space>
        </form>
    </n-grid-item>
  </n-grid>


</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
const axios = require('axios');
const msal = require("@azure/msal-browser");


const msalConfig = {
    auth: {
        clientId: '3c7c2ee0-bae1-4f89-892a-ddf419dbf71c'
    }
};

var loginRequest = {
    scopes: ["user.read", "mail.send"] // optional Array<string>
};


/* add https://vee-validate.logaretm.com/v4/ */

export default defineComponent({
  name: 'RequestForm',
  setup: () => {


  /* move this to root, and then include to src/repository/user/index.js, then const login= */
    const msalInstance = new msal.PublicClientApplication(msalConfig);
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    const EventType = ''

    msalInstance.addEventCallback((event) => {
      // set active account after redirect
      if (event.eventType && event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
      }
    }, error=>{
      console.log('error', error);
    });


    const loginUser = async () => {
            // handle auth redired/do all initial setup for msal
          let accountinfo = await msalInstance.handleRedirectPromise().then(authResult=>{
              // Check if user signed in 
              console.log(authResult);
              const account = msalInstance.getActiveAccount();

              if(!account){
                // redirect anonymous user to login page 
                msalInstance.loginRedirect(loginRequest);
              }else{
                return account
              }
            }).catch(err=>{
              // TODO: Handle errors
              console.log(err);
            });
            return accountinfo
    }

    const categoryFilterOptions = [];


    const columns = [
      {
        type: 'selection'
      },
      {
        title: 'Category',
        key: 'Category',
        defaultFilterOptionValues: [], //'Affiliate', 'Email'
        filterOptions: categoryFilterOptions,
        filter (value, row) {
          return ~row.Category.indexOf(value)
        }
      },
      {
        title: 'Campaign',
        key: 'Campaign'
      },
      {
        title: 'Scope',
        key: 'Scope'
      }
    ]
    const checkedRowKeysRef = ref([])

    const campaign_categories_options = ref([]);
    const accountinfo_global = ref([]);

    const downloadJson = async (url) => {
        let res = await axios.get(url, { params: { answer: 42 } });
        //the following is required only because the initial content from adf is not clean json
        var clean_json = res.data.replace(/[\r\n]+/g,",").replace(",{}","");
        clean_json = '['+clean_json.slice(0, -1)+']'
        clean_json = clean_json.replace(',]',']')
        clean_json = JSON.parse(clean_json);
        return clean_json
    };

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    } 

    const getRemoteData = () => {
      downloadJson('https://dtcecommerceestoresprod.blob.core.windows.net/datascience/public/campaign_categories.json').then((res) => {
       campaign_categories_options.value = res
        let filter_categories = campaign_categories_options.value.map(({ Category }) => Category)
        var filter_categories_unique = filter_categories.filter(onlyUnique);
        filter_categories_unique.forEach(function (item) {
          categoryFilterOptions.push({'label':item,'value':item})
        });
      })     
      loginUser().then((accountinfo)=>{
        accountinfo_global.value = accountinfo
       
      })
    }

    onMounted(() => {
      getRemoteData()
    })

    const tableRef = ref(null)


    return {
      table: tableRef,
      campaign_categories_options,
      accountinfo_global,
      columns,
      checkedRowKeys: checkedRowKeysRef,
      pagination: { pageSize: 500 },
      handleCheck (rowKeys) {
        checkedRowKeysRef.value = rowKeys
      },
      filterCategory () {
        tableRef.value.filter({
          Category: ['Affiliate']
        })
      },
      clearFilters () {
        tableRef.value.filter(null)
      }
    }

  },  
  data(){

    console.log('8-8-',this.accountinfo_global);

    return{
        errors: [],
        success: [],
        range: ref([1553135260000, Date.now()]),
        posts:{
            start_date:null,
            end_date:null,
            campaign:null,
            email_recipients:this.accountinfo_global.email
        }
    }
  }, 
  methods:{
     checkForm: function (e) {
      this.errors = [];
        console.warn('----',this.checkedRowKeys.length)

      if (!this.range) {
        this.errors.push("range required.");
      }

      if (this.checkedRowKeys.length == 0) {
        this.errors.push("campaign checkbox required.");
      }

      if (!this.posts.email_recipients) {
        this.errors['email']=1;
      } else if (!this.validEmail(this.posts.email_recipients)) {
        this.errors['email']=1;
      }
      console.warn(this.errors)
      if (!this.errors.length) {
        e.preventDefault();
        return this.postData(e)
      }
      e.preventDefault();

    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    postData(e){
        console.warn(this.posts)
        var start_date = new Date(this.range[0]);
        var end_date = new Date(this.range[1] );
        this.posts.start_date = start_date.toISOString().split('T')[0]
        this.posts.end_date = end_date.toISOString().split('T')[0]
        this.posts.campaign = this.checkedRowKeys
        this.posts.email_recipients = this.accountinfo_global.username
        this.axios.post('https://prod-134.westeurope.logic.azure.com:443/workflows/74f9816dbcb34b63838f26b64df9ba88/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ZP0T_S6zyXnHCFgifMMAnehIniJy09iPbNHIBj4glLA',this.posts)
        .then((result)=>{
            console.warn(result)
            if(result.data.success == "True"){
              this.success.push('success');
            }else{
              this.errors['backend_error']=1;
            }
        })
        e.preventDefault();
    }
  }
})
</script>